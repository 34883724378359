@use '../../../styles/index' as *;

.menuitem_container {
    width: 100%;
    margin: 1rem 0;

    display: flex;
    flex-direction: column;
    border-bottom: 1px solid transparent;
    padding-bottom: 5px;

    &:hover {
    color: $color-golden;
    transform: scale(0.98);
    transition: .3s ease;
    border-bottom: 1px solid $color-golden;
    padding-bottom: 5px;

        .menuitem_title {
            color: $color-golden;
        }
        .menuitem_dash {
            background-color: $color-golden;
        }
    }
}

.menuitem_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menuitem_sub {
    width: 100%;
    margin-top: 0.2rem;
}

.menuitem_tag {
    color: $color-grey;
    font-size: 0.8rem;
    margin-top: 0.2rem;
}

.menuitem_name {
    flex: 1;
}

.menuitem_dash {
    width: 90px;
    height: 1px;
    background: $color-burgundy;
    margin: 0 1rem;
}

.menuitem_price {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
@use '../../styles/index' as *;

.navbar_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    align-items: center;
    background: $color-burgundy;
    padding: 2rem 2rem;
    max-height: 65px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
}

.navbar_logo,
.navbar_logo img {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    height: 40px;
    cursor: pointer;
}

.navbar_logo p {
    font-family: $font-base;
    font-weight: 600;
    font-size: 28px;
    color: $color-white;
}

.navbar_links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    cursor: pointer;
}

.navbar_links li {
    margin: 0 1rem;
    cursor: pointer;
    border-bottom: 2px solid transparent;

    &:hover {
        color: $color-golden;
        transform: scale(0.98);
        transition: .3s ease;
        border-bottom: 2px solid $color-golden;
    }
}

.navbar_login {
    display: flex;
    justify-content: center;
    margin-left: auto;
    align-items: center;
}

.navbar_login a {
    margin: 0 1rem;
    text-decoration: none;
    transition: .5s ease;
}

.navbar_login a:hover {
    border-bottom: 1px solid $color-golden;
}

.navbar_login div {
    width: 1px;
    height: 30px;
    background: $color-grey;
}

.navbar_smallscreen {
    display: none;
}

.navbar_smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $color-burgundy;
    transition: .5s ease;

    flex-direction: column;
    z-index: 999;
}

.navbar_smallscreen_overlay .overlay_close {
    font-size: 1.75rem;
    color: $color-golden;
    cursor: pointer;

    position: absolute;
    top: 20px;
    right: 20px;
}

.navbar_smallscreen_links {
    list-style: none;
}

.navbar_smallscreen_links li {
    margin: 2rem;
    cursor: pointer;
    color: $color-golden;
    font-size: 2rem;
    text-align: center;
    font-family: $font-base;
}

.navbar_smallscreen_links li:hover {
    color: $color-white;
}

.menu_item {
    cursor: pointer;
}

.menu_item:hover {
    color: $color-golden;
}

@media screen and (min-width: 2000px) {
    .navbar_logo img {
        // width: 190px;
        height: 50px;
    }

    .navbar_container {
        padding: 2.5rem 3rem;
    }
}

@media screen and (max-width: 1150px) {
    .navbar_links {
        display: none;
    }

    .navbar_smallscreen {
        display: flex;
    }

    .navbar_smallscreen .navbar_hamburger {
        color: $color-white;
        font-size: 28px;
    }

    .navbar_hamburger {
        cursor: pointer;
        margin-left: 1rem;
    }

    .navbar_logo p {
        font-size: 28px;
    }
}

@media screen and (max-width: 650px) {
    .navbar {
        padding: 1.5rem;
    }

    .navbar_container {
        padding: 2rem 1.4rem;
    }

    .navbar_login {
        display: none;
    }

    .navbar_logo img {
        // width: 110px;
        height: 35px;
    }

    // .navbar_logo p {
    //     display: none;
    // }

    .navbar_logo p {
        font-size: 26px;
    }
}
@use '../../styles/index' as *;

.special {
    display: flex;
    flex-direction: column;
    background: $color-white;
}

.special_title {
    margin-bottom: 2rem;
    text-align: center;
}

.special_menu {
    width: 100%;
    margin: 2rem 0;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
}

.special_menu_heading {
    font-family: $font-base;
    font-weight: 600;
    font-size: 38px;
    line-height: 42px;
    color: $color-burgundy;
    text-decoration: underline;
}

.special_menu_logo,
.special_menu_cocktails {
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.special_menu_img {
    position: relative;
    inset: 0;
    max-width: 325px;
    max-height: 330px;
    margin: 10rem 2rem 0;
    padding: 1rem 0 4rem;
}

.special_menu_img img {
    z-index:0;
    opacity: 0.6;
    width: 100%;
    height: auto;
}

.special_menu_items {
    display: flex;
    flex-direction: column;

    margin: 2rem 0;
    width: 100%;
}

.button_wrapper {
  margin-top: 15px;
}

@media screen and (min-width: 2000px) {
    .special_menu_img {
        max-width: 400px;
        margin-top: 8rem;
    }

    .special_menu_img img {
        max-height: 360px;
    }
}

@media screen and (max-width: 1150px) {
    .special_menu {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .special_menu_img {
        max-width: 350px;
        margin: 2rem 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .special_menu_img {
        max-width: 250px;
        margin: 0;
    }

    .special_menu_heading {
        font-size: 35px;
        line-height: 48.5px;
    }
}
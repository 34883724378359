@use '../../styles/index' as *;

  .wrapper_img {
    position: relative;
    z-index: 99;
    margin: 0 auto;
  }

  .contact_location {
    color: $color-golden; 
    margin-bottom: 1rem;
  }

  .contact_hours {
    color: $color-golden; 
    margin: 1rem 0;
  }

  .follow_title {
    margin-top: 3.5rem;
  }

  .contact_social {
    margin: 1rem 0;
  }

  .contact_title {
    margin-top: 1rem;
  }

  @media screen and (min-width: 2000px) {
    .contact_info {
      max-width: 60%;
    }
  }

  @media screen and (max-width: 1150px) {
  .contact_info {
      max-width: 80%;
    }
  }

  @media screen and (max-width: 850px) {
  .contact_info {
    max-width: 100%;
  }
}
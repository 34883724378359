@use '../../styles/index' as *;

.catering {
    padding: 4rem 4rem;
    max-width: 1200px;
    border: 1px solid $color-burgundy;
    background: $color-white;
    z-index: 2;
}

.catering_heading {
    text-align: center;
}

.catering_text {
    padding: 0 1rem;
    text-align: center;
}

.catering_input {
    flex-direction: column;
    margin-top: 3rem;
}

.catering_input input,
textarea {
    width: 100%;
    max-width: 1000px;
    min-width: 280px;
    border: 1px solid $color-burgundy;
    border-radius: 2px;
    font-size: 1.25rem;
    font-family: $font-base;
    color: $color-black;

    // margin-right: 2rem;
    padding: 0.5rem 1rem;
    background: $color-white;
}

input,
textarea {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.catering_input button {
    width: max-content;
}

@media screen and (min-width: 2000px) {
    .catering_input input {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 990px) {
    .catering_input {
        flex-direction: column;
        width: 100%;
    }

    .catering_input input {
        margin: 0 0 2rem 0;
        font-size: 1rem;
    }
}

@media screen and (max-width: 650px) {
    .catering {
        padding: 2rem 0;
        border: none;
    }

    .catering_input input,
    textarea {
        max-width: 400px;
    }
}

@media screen and (max-width: 300px) {
    .catering_heading h1 {
        font-size: 32px;
        line-height: 50px;
    }
}
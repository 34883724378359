@use '../styles/index' as *;
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap");

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-display: optional;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

ul {
  list-style-type: none;
}

a {
  color: inherit;
  text-decoration: none;
}

/* Scrollbar works on Firefox */
body {
  scrollbar-width: thin;
  scrollbar-color: $color-black $color-gray;
}

/* Scrollbar works on Chrome, Edge, and Safari */
body::-webkit-scrollbar {
  width: 12x;
}

body::-webkit-scrollbar-track {
  display: none;
}

body::-webkit-scrollbar-thumb {
  background-color: $color-gray;
  border-radius: 20px;
  border: 3px solid $color-black;
}

.slide_bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide_bottom {
  0% {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide_bottom {
  0% {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cormorant {
  font-family: $font-base;
  color: $color-black;
  font-weight: 700;
  text-transform: capitalize;
  font-feature-settings: "tnum" on, "lnum" on;
  line-height: 26px;
  font-size: 26px;

  &.white {
    color: $color-white;
  }

  &.golden {
    color: $color-golden;
  }
}

.opensans {
  font-family: $font-alt;
  color: $color-black;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: capitalize;
  line-height: 24px;
  font-size: 20px;

  &.white {
    color: $color-white;
  }

  &.golden {
    color: $color-golden;
  }

  &.menu {
    font-size: 19px;
  }
}

.custom_button {
  background-color: transparent;
  font-family: $font-base;
  color: $color-burgundy;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 24px;
  font-size: 24px;
  padding: 0.7rem;
  border-radius: 1px;
  border: none;
  outline: none;
  cursor: pointer;
  border-bottom: 1px solid $color-burgundy;
  text-align: center;

  &:hover {
    background-color: $color-burgundy;
    color: $color-white;
    transform: scale(0.96);
    transition: .4s ease;
    font-weight: 700;
    border-bottom: 1px solid $color-golden;
  }

  &.white {
    color: $color-white;
    border-bottom: 1px solid $color-golden;

    &:hover {
      background-color: $color-white;
      color: $color-burgundy;
      transform: scale(0.96);
      transition: .4s ease;
      font-weight: 700;
      border-bottom: 1px solid $color-golden;
    }
  }
}

.section_padding {
  padding: 6rem 6rem;

  &.video {
    padding: 0;
  }
}

.img_padding {
  padding: 17px;
}

.app_bg {
  // background: url('../assets/bg.png') no-repeat center center fixed;
  background-size: cover;
  background-color: $color-burgundy;

  &.white {
    background-color: $color-white;
  }
}

.app_wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 4rem;

  width: 100%;
  min-height: 85%;
}

.app_wrapper_info {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.app_wrapper_img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 2rem;

  &::before {
    content: '';
    width: 60%;
    height: 60%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: $color-golden no-repeat center;
  }

  &::after {
    content: '';
    width: 60%;
    height: 60%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    background: $color-golden no-repeat center;
  }
}

.app_wrapper_img_reverse {
  justify-content: flex-start;
  margin-right: 2rem;
}

.headtext_cormorant {
  font-family: $font-base;
  color: $color-burgundy;
  line-height: 50px;
  font-size: 46px;
  text-transform: capitalize;

  &.white {
    color: $color-white;
  }

  &.golden {
    color: $color-golden;
  }
}

.spoon_img {
  position: relative;
  margin-top: 1rem;
  width: 45px;
}

.app_container {
  max-width: 1700px;
  margin: 0 auto;
}

@media screen and (min-width: 2000px) {
  .custom_button {
    line-height: 26px;
    font-size: 32px;
  }

  .cormorant {
    line-height: 28px;
    font-size: 32px;
  }

  .spoon_img {
    width: 80px;
  }

  .section_padding {
    padding: 7rem 10rem;

    &.video {
      padding: 2rem 18rem 4rem;
    }
  }

  .app_wrapper {
    gap: 8rem;
  }

  .opensans {
    &.menu {
      font-size: 21px;
      line-height: 32px;
    }
  }
}

@media screen and (max-width: 1500px) {
  .app_wrapper {
    flex-direction: column;
  }
}

@media screen and (max-width: 1150px) {
  .app_wrapper.map {
    flex-direction: column;
  }

  .app_wrapper_img {
    margin: 5rem 0 0 0;
  }

  .app_wrapper_img_reverse {
    margin: 0 0 5rem 0;
  }

  .app_wrapper_img img {
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .section_padding {
    padding: 4rem;
  }
}

@media screen and (max-width: 650px) {
  .section_padding {
    padding: 4rem 2rem;
  }

  .cormorant {
    font-size: 20px;
  }

  .opensans {
    font-size: 18px;

    &.menu {
      font-size: 16px;
    }
  }

  .app_wrapper {
    gap: 0.5rem;
  }
}

@media screen and (max-width: 450px) {
  .opensans {
    font-size: 16px;
  }

  .section_padding {
    padding: 3.5rem 1.5rem;
  }

  .cormorant {
    font-size: 18px;
  }

  .headtext_cormorant {
    line-height: 38px;
    font-size: 28px;
  }

  .app_wrapper {
    gap: 0.2rem;
  }
}
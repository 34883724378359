@use '../../styles/index' as *;

.gallery_container {
    width: 100%;
    flex-direction: row;

    background: $color-white;
    padding: 4rem 0 4rem 6rem;
}

.gallery_content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    min-width: 500px;
    padding-right: 2rem;
}

.gallery_content button {
    margin-top: 1rem;
}

.gallery_images {
    flex: 1;
    display: flex;
    flex-direction: row;
    max-width: 55%;
    position: relative;
}

.gallery_images_container {
    display: flex;
    flex-direction: row;
    width: max-content;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;  
}

.gallery_images_container::-webkit-scrollbar {
    display: none;
}

.gallery_images_card {
    position: relative;
    min-width: 800px;
    // height: 447px;
    width: 800px;
    height: 600px;
    margin-right: 2rem;
    background: $color-black;
}

.gallery_image_icon {
    position: absolute;
    color: #fff;
    font-size: 1.5rem;
    opacity: 0;
    transition: .5s ease;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.gallery_images_card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    transition: .5s ease;
}

.gallery_image_icon p {
    font-size: 1.5rem; 
    font-weight: bold
}

.gallery_images_card:hover img {
    opacity: .35;
}

.gallery_images_card:hover .gallery_image_icon {
    opacity: 1;
}

.gallery_images_arrows {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 1rem;
    position: absolute;
    bottom: 5%;
}

.gallery_content_intro {
    color: $color-grey; 
    margin: 2rem 0;
    max-width: 85%;
    color: $color-black;
    line-height: 1.4;
    font-size: 1.2rem;
}

.gallery_arrow_icon {
    color: $color-golden;
    font-size: 4rem;
    cursor: pointer;
    background: $color-burgundy;
    border-radius: 5px;
}

.gallery_arrow_icon:hover {
    color: $color-white;
}

@media screen and (min-width: 2000px) {
    .gallery_content button {
        margin-top: 2rem;
    }

    .gallery_content {
        min-width: 700px;
        padding-right: 4rem;
    }

    .gallery_images {
        max-width: 66%;
    }
    .gallery_images_card {
        min-width: 1000px;
        width: 1000px;
        height: 700px;
    }
}

@media screen and (max-width: 1150px) {
    .gallery_container {
        flex-direction: column;
    }

    .gallery_images {
        max-width: 100%;
        margin: 5rem 0 5rem -7.5vw;
    }
}

@media screen and (max-width: 850px) {
    .gallery_container {
        padding: 4rem 0 4rem 4rem;
    }
    .gallery_content_intro {
        font-size: 1rem;
    }
}

@media screen and (max-width: 650px) {
    .gallery_container {
        padding: 4rem 0 4rem 2rem;
    }  
    
    .gallery_content {
        min-width: 100%;
        padding: 0 0.5rem 0 1rem;
    }

    .gallery_images_card {
        min-width: 95vw;
        min-height: 300px;
        height: 300px;
    }

    .gallery_arrow_icon {
        font-size: 2.5rem;
    }
}
@use "../../styles/index" as *;

.footer {
  width: 100%;
  position: relative;
  // z-index: 1;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  background-color: $color-black;
  // padding-top: 0;
  margin: 0 0;
  background: $color-burgundy;
}

.footer_copyright {
  padding: 1rem 0;
  max-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 3rem;
  z-index: 1;
}

.footer_logo {
  margin: 0 1rem;
}

.footer_links {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 2;

  padding: 0 2rem;
}

.footer_links_contact,
.footer_links_logo,
.footer_links_work {
  flex: 1;
  margin: 1rem;
  text-align: center;
}

.footer_links_logo img:first-child {
  width: 210px;
  margin-bottom: 0.75rem;
  padding: 0.5rem 0;
  border-radius: 10% !important;
}

.footer_links_work p:nth-child(2n + 1) {
  margin-bottom: 1rem;
}

    .footer_links_icons {
      text-align: center;
      margin-top: 0.5rem;
    }

    .footer_links_icons svg {
      color: $color-white;
      margin: 0.5rem;
      font-size: 24px;
      cursor: pointer;
    }

    .footer_links_icons svg:hover {
      color: $color-golden;
    }



.footer_headtext {
  font-family: $font-base;
  color: $color-golden;
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  font-size: 32px;
  line-height: 41.6px;
  margin-bottom: 1rem;
}

@media screen and (min-width: 2000px) {
  .footer_headtext {
    font-size: 51px;
    line-height: 61.6px;
  }
}

@media screen and (max-width: 1150px) {
  .footer_links {
    align-items: center;
    flex-direction: column;
    padding: 0;
  }

  .footer_links_contact,
  .footer_links_logo,
  .footer_links_work {
    text-align: center !important;
    flex: 1 1;
    margin: 2rem 0;
    width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .footer {
    padding: 0 0 2rem 0;
  }
}

@media screen and (max-width: 350px) {
  .footer_links_logo img:first-child {
    width: 80%;
  }
}

@use "../../styles/index" as *;

.video_wrapper {
    background-color: $color-white;
}

.container_video {
  height: 80%;
  position: relative;
  background-color: transparent;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.video_overlay {
  position: absolute;
  inset: 0;
}

.video_overlay_circle {
  width: 150px;
  height: 150px;
  outline: none;
  background: transparent;
  border-radius: 50%;
  border: none;
  cursor: pointer;

  @media screen and (min-width: 1000px) {
    width: 325px;
    height: 325px;
  }

  @media screen and (min-width: 2000px) {
    width: 550px;
    height: 550px;
  }
}

.video_icon {
  color: $color-white;
  font-size: 30px;

  @media screen and (min-width: 1000px) {
    font-size: 60px;
  }
}
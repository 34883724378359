@use '../../styles/index' as *;

.header {
  background-color: $color-white;
  display: flex;
  flex-wrap: wrap-reverse;

  .header_h1 {
    font-family: $font-base;
    color: $color-burgundy;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 58px;
    font-size: 48px;
  }

  .header_p {
    margin: 2rem 0;
  }

  .header_img img {
    max-width: 80%;
    object-fit: cover;
  }

  .header_padding {
    padding-top: 2rem;
  }

  .wrapper_info {
    flex: 1;
  }

  .wrapper_img {
    position: relative;
    z-index: 99;
    margin: 0 auto;
  }
}

.wrapper_img img {
  width: 100%;
  max-width: 400px;
  // border: 10px solid $color-burgundy;
}


@media screen and (min-width: 2000px) {
  .header_h1 {
    line-height: 62px;
    font-size: 52px;
  }

  .wrapper_img img {
    width: 100%;
    max-width: 500px;
  }
}

@media screen and (max-width: 650px) {
  .header_h1 {
    line-height: 46px;
    font-size: 32px;
  }

  .wrapper_img img {
    padding: 2rem 0 0;
    width: 100%;
    max-width: 250px;
  }
}

@media screen and (max-width: 450px) {
  .header_h1 {
    line-height: 38px;
    font-size: 28px;
  }

  .wrapper_img img {
    padding: 1rem 0 0;
    width: 100%;
    max-width: 250px;
  }
}
// global variables

  $font-base: "Cormorant Upright", serif;
  $font-alt: "Open Sans", sans-serif;

  $color-golden: #e8843c;
  $color-black: #0c0c0c;
  $color-burgundy: #380000;
  $color-light-black: #070707;
  $color-gray: #545454;
  $color-dark-gray: #202020;
  $color-crimson: #E0342E;
  $color-invalid: #ffdddd;
  $color-grey: #f5f5f5;
  $color-white: #fdfbf5;

  $transition: all 0.3s linear;
  $spacing: 0.1rem;
  $radius: 0.5rem;
  $light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  $dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  $max-width: 1170px;
  $fixed-width: 620px;
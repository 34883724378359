@use '../../styles/index' as *;

.social_links_icons {
  // display: flex;
  // justify-content: space-around;
  align-items: center;
}

.social_icon {
  color: $color-white;
  margin: 0.5rem;
  font-size: 25px;
  cursor: pointer;

  &:hover {
    color: $color-golden;
    transform: scale(0.98);
    transition: .5s ease;
  }
}

// .social_icon:hover {
//   color: $color-black;
// }
@use '../../styles/index' as *;

.map_container {
    border: 10px solid $color-golden;
    border-radius: 5px;

    @media screen and (max-width: 450px) {
        border-top: 10px solid $color-golden;
        border-right: none;
        border-bottom: 10px solid $color-golden;
        border-left: none;
        border-radius: 0;
    }
}
.map_wrapper {
    position: flex;
    text-align: right;
    height: 450px;
    max-width: 800px;

    @media screen and (min-width: 2000px) {
        height: 550px;
        width: 1000px;
        max-width: 1200px;
    }

    @media screen and (min-width: 1150px) {
        height: 450px;
        min-width: 800px;
    }

    @media screen and (max-width: 450px) {
        height: 350px;
        min-width: 300px;
        width: 100vw;
    }
}

iframe {
    height: 450px;
    width: 800px;

    @media screen and (min-width: 2000px) {
        height: 550px;
        width: 1000px;
        max-width: 1200px;
    }

    @media screen and (min-width: 1150px) {
        height: 450px;
        min-width: 800px;
    }

    @media screen and (max-width: 450px) {
        height: 350px;
        min-width: 300px;
        width: 100vw;
    }
}